import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home';
import TheChart from './chart';

const Pages = (mainProps) => {

	return (
        <Switch>
            <Route path='/chart/:year' render={(props) => (<TheChart {...props} />)} />
            <Route path='/' render={(props) => (<Home {...props} />)} />
        </Switch>
    )
}

export default Pages;