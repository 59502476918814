const Switch = (props) => {

  const doSwitch = () => {
    props.onChange(!props.on);
  }
  
  return (
    <div className={"switch " + (props.on ? 'on' : '')} onClick={ () => doSwitch() }>
      <div className="circle"></div>
    </div>
  )
}


export default Switch;
