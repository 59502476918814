import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useParams } from 'react-router-dom';
import './index.css';
import Pages from './pages';
import Footer from './footer';

const Game = () => {

  const gotoUrl = (url) => {
    Router.push(url);
  }

	return (
    <Router>
      <Pages />
      <Footer />
    </Router>
  )
}


ReactDOM.render(
  <Game />,
  document.getElementById('root')
);
