import Moment from 'moment';

const Footer = () => {
    return (
        <div className="footer desk">
            <div className="width">
            <div className="copy">&copy; {Moment().format('YYYY')} TheTimeProject.org</div>
            <div className="nav">
                <a href="/">Home</a>
                <a href="https://blog.thetimeproject.org/about">About</a>
                <a href="https://blog.thetimeproject.org">Blog</a>
                <a href="https://blog.thetimeproject.org/contact">Contact</a>
            </div>
            </div>
        </div>
    )
}

export default Footer;