import { BrowserRouter as Router, Link } from 'react-router-dom';
import { useState } from 'react';
import Moment from 'moment';

const Menu = () => {

    const [ menuOpen, setMenuOpen ] = useState(false);

    return (
        <div className={"top-menu visible" + ( menuOpen ? ' menu-open' : '')}>
            <div className="width">
                <span className="logo"><Link to={'/'}>The <span>TIME</span> Project</Link></span>
                <div className="nav">
                    <a href="https://blog.thetimeproject.org/about">About</a>
                    <a href="https://blog.thetimeproject.org">Blog</a>
                    <a href="https://blog.thetimeproject.org/contact">Contact</a>
                    <div className="copy mob">&copy; {Moment().format('YYYY')} TheTimeProject.org</div>
                </div>
            </div>
            <div className="mob-toggle mob" onClick={ () => setMenuOpen(!menuOpen) }>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Menu;