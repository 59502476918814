import React, { Component, useEffect, useState, useRef } from 'react';
import Highcharts from 'highcharts/highstock';
import {
  HighchartsStockChart, Chart, withHighcharts, XAxis, YAxis, Title, Legend,
  AreaSplineSeries, AreaSeries, SplineSeries, Navigator, RangeSelector, Tooltip
} from 'react-jsx-highstock';
import Moment from 'moment';
import Menu from './menu';
import Switch from './switch';
import { useParams } from 'react-router-dom';


const smoothScroll = (parent, element) => {
    var finalPosition = element.offsetTop;
    var currentPosition = parent.scrollTop;
    var time = 500;
    var unit = (finalPosition - currentPosition) / time;
    for(let i = 1; i <= time; i++){
        window.setTimeout(() => {
            parent.scrollTop = currentPosition + unit * i;
        }, i);
    }
}

const TheChart = (props) =>  {

  const [ djData, setDjData ] = useState(null);
  const [ data, setData ] = useState(null);
  const [ selectedIndex, setSelectedIndex ] = useState(-1);
  const [ axisType, setAxisType ] = useState('linear');
  const [ logarithmicOn, setLogarithmicOn ] = useState(false);
  const [ fromYear, setFromYear ] = useState(null);
  const [ fromYearError, setFromYearError ] = useState(false);
  const [ toYear, setToYear ] = useState(null);
  const [ toYearError, setToYearError ] = useState(false);
  const [ showYears, setShowYears ] = useState({from: null, to: null });
  const [ ready, setReady ] = useState(false);
  const [ theTickInterval, setTheTickInterval ] = useState(undefined);
  const [ chartHeight, setChartHeight ] = useState(400);

  let { year } = useParams();
  let initialMin = Date.parse('1/1/' + year);
  let initialMax = Date.parse('12/31/' + year);

  useEffect(() => {
    if(fromYear >= 1000 && toYear >= 1000){
        if(data && djData && Highcharts.charts[Highcharts.charts.length - 1].series[0]){
            let firstYear = Moment(data[0].date).year();
            let lastYear = Moment(data[data.length - 1].date).year();
            if(fromYear >= firstYear && toYear <= lastYear && fromYear <= toYear){
                setToYearError(false);
                setFromYearError(false);
                Highcharts.charts[Highcharts.charts.length - 1].series[0].xAxis.setExtremes(Date.parse('1/1/' + fromYear), Date.parse('12/31/' + toYear));
            }else{
                if(fromYear > toYear){ 
                    setFromYearError(true);
                    setToYearError(true);
                }else{
                    if(fromYear < firstYear){
                        setFromYearError(true);
                    }
                    if(toYear > lastYear){
                        setToYearError(true);
                    }
                }
            }
        }
    }
    setShowYears({ from: fromYear, to: toYear });
  }, [ fromYear, toYear])


  useEffect(() => {
    fetch("/config.json").then(res => res.json()).then(res => {
        fetch(res.api + '/data.php').then(res => res.json()).then(res => {
            setData(res.map((item, key) => {
                return { ...item, itemKey: key }
            }).filter(item => item.text != null && item.text != ''));
            setFromYear(year);
            setToYear(year);
            setDjData(res.map(item => {
                let theDate = Date.parse(item.date);
                return [
                    theDate,
                    item.value
                ];
            }));
        });
    });
    if(window.innerWidth <= 960){
        setChartHeight(200);
    }
  }, []);

  useEffect(() => {
    if(data){
        setTimeout(() => {
            setReady(true);
            let found = false;
            for(let i = 0; i < data.length; i++){
                if(Moment(data[i].date).year() == year && !found){
                    document.getElementById("items").scrollTop = document.getElementById('item-' + i).offsetTop;
                    found = true;
                }
            }
        }, 1000);
    }
  }, [ data ])

  const chartClicked = (timestamp) => {
    let foundIndex = -1;
    for(let i = 0; i < data.length; i++){
      if(i < data.length - 1){
        let thisTs = Date.parse(data[i].date);
        let nextTs = Date.parse(data[i + 1].date);
        if(timestamp >= thisTs && timestamp <= nextTs){
            foundIndex = i;
        }
      }
    }
    setSelectedIndex(foundIndex);
    smoothScroll(document.getElementById("items"), document.getElementById('item-' + foundIndex));
  }


  const onItemHover = (key) => {
    if(Highcharts.charts[Highcharts.charts.length - 1]){
        let itemKey = data[key].itemKey;
        let chart = Highcharts.charts[Highcharts.charts.length - 1];
        let thePoint = chart.series[0].data[itemKey];
        if(thePoint == undefined){
            for(let i = 0; i < chart.series[0].data.length; i++){
                if(thePoint == undefined && i > itemKey){
                    thePoint = chart.series[0].data[i];
                }
            }
        }
        let extremes = chart.series[0].xAxis.getExtremes();
        chart.xAxis[0].removePlotBand('highlight-band');
        if(key < data.length - 1){
            let fromDate = Date.parse(data[key].date);
            let toDate = Date.parse(data[key + 1].date);
            let color = '#E6EDF8';
            if(Moment(extremes.max).year() - Moment(extremes.min).year() >= 40){
                color = '#E72121';
            }else if(Moment(extremes.max).year() - Moment(extremes.min).year() >= 10){
                color = '#BCC9DC';
            }
            chart.xAxis[0].addPlotBand({
                from: fromDate,
                to: toDate,
                color: color,
                id: 'highlight-band'
            });
        }

    }
  }

  const onItemOut = (key) => {
    if(Highcharts.charts[Highcharts.charts.length - 1] && Highcharts.charts[Highcharts.charts.length - 1].series[0].data[key] && Highcharts.charts[Highcharts.charts.length - 1].series[0].data[key].isInside){
        Highcharts.charts[Highcharts.charts.length - 1].series[0].data[key].setState('');
    }
  }

  const toggleLogarithmic = (on) => {
    setLogarithmicOn(on);
    if(Highcharts.charts[Highcharts.charts.length - 1] && Highcharts.charts[Highcharts.charts.length - 1].series[0]){
        if(on){
            Highcharts.charts[Highcharts.charts.length - 1].series[0].yAxis.update({ type: 'logarithmic' });
        }else{
            Highcharts.charts[Highcharts.charts.length - 1].series[0].yAxis.update({ type: 'linear' });
        }
    }
  }

  const onItemClick = (key) => {
    let date = Moment(data[key].date);
    if(Highcharts.charts[Highcharts.charts.length - 1]){
        let extremes = Highcharts.charts[Highcharts.charts.length - 1].series[0].xAxis.getExtremes();
        let startYear = date.year();
        let endYear = date.year();
        let extremeStartYear = Moment(extremes.min).year();
        let extremeEndYear = Moment(extremes.max).year();
        if(extremeEndYear - extremeStartYear >= 5){
            let firstYear = Moment(data[0].date).year();
            let lastYear = Moment(data[data.length - 1].date).year();
            startYear -= startYear % 10;
            endYear = startYear + 9;
            if(startYear < firstYear){
                startYear = firstYear;
            }
            if(endYear > lastYear){
                endYear = lastYear;
            }
        }

        Highcharts.charts[Highcharts.charts.length - 1].series[0].xAxis.setExtremes(Date.parse('1/1/' + startYear), Date.parse('12/31/' + endYear));
        // console.log('1/1/' + startYear, '12/31/' + endYear);
        // console.log(Date.parse('1/1/' + startYear), Date.parse('12/31/' + endYear));
        setTimeout(onItemHover, 100, key);
    }

    // let extremes = Highcharts.charts[Highcharts.charts.length - 1].series[0].xAxis.getExtremes();
    // let startIndex = 0;
    // let endIndex = data.length - 1;
    // for(let i = 0; i < data.length; i++){
    //   if(data[i].date == date){
    //     console.log('found current point');
    //     currentPointIndex = i;
    //     if(i - halfWidth >= 0){
    //       startIndex = i - halfWidth;
    //     }
    //     if(i + halfWidth < data.length){
    //       endIndex = i + halfWidth;
    //     }
    //   }
    // }
    // let startDate = Date.parse(data[startIndex].date);
    // let endDate = Date.parse(data[endIndex].date);
    // if(currentPointIndex >= extremes.min && currentPointIndex <= extremes.max){
    //   showCurrentPoint();
    // }
    // Highcharts.charts[Highcharts.charts.length - 1].series[0].xAxis.setExtremes(startDate, endDate);
  }

  const onChartOver = (e) => {
    let point = null;
    let event = null;
    let chart = Highcharts.charts[Highcharts.charts.length - 1];
    event = chart.pointer.normalize(e); // Find coordinates within the chart
    point = chart.series[0].searchPoint(event, true); // Get the hovered point
    if (point && data) {
        for(let i = 0; i < data.length; i++){
            if(data[i] && data[i+1]){
                let thisTs = Date.parse(data[i].date);
                let nextTs = Date.parse(data[i + 1].date);
                if(point.category >= thisTs && point.category <= nextTs){
                    chart.xAxis[0].removePlotBand('highlight-band');
                    chart.xAxis[0].addPlotBand({
                        from: thisTs,
                        to: nextTs,
                        color: '#E6EDF8',
                        id: 'highlight-band'
                    });
                }
            }
        }
    }
  }

  const updateTickInterval = () => {

    let chart = Highcharts.charts[Highcharts.charts.length - 1];
    let extremes = chart.series[0].xAxis.getExtremes();
    let startYear = Moment(extremes.min).year();
    let endYear = Moment(extremes.max).year();


    let displayedValues = djData.filter(item => {
        return item[0] >= extremes.min && item[0] <= extremes.max
    }).map(item => {
        return item[1];
    });

    let minVal = Math.min(...displayedValues);
    let maxVal = Math.max(...displayedValues);
    let paddingValue = Math.round((maxVal - minVal) * 0.05);
    if(displayedValues.length == 0){
        minVal = 0;
    }else if(displayedValues.length == 1){
        minVal = 0;
    }
    let axisMin = (minVal - paddingValue > 0 ? minVal - paddingValue : 0);
    console.log('min: ', minVal, displayedValues);
    console.log('axisMin: ', axisMin);
    console.log('extremes: ', extremes);

    chart.series[0].yAxis.update({
        min: axisMin
    });

    setShowYears({ from: startYear, to: endYear });
    if(endYear - startYear <= 1){
        setTheTickInterval(1000 * 60 * 60 * 24 * 30);
    }else if(endYear - startYear >= 9 && endYear - startYear <= 11){
        setTheTickInterval(1000 * 60 * 60 * 24 * 365);
    }else{
        console.log('other');
        setTheTickInterval(undefined);
    }
  }


  return (
    <div>
        <div className={"hider" + (ready ? ' hidden' : '')}>
            <div className="spinner"></div>
        </div>
        <Menu />
        { (djData && data) && (
            <div className="width">
            <div className="main" id="main">
                <div className="chart-wp" id="chart-wp">
                <div className="chart-container">
                    <div className="custom-controls">
                        <div className="left">
                            <Switch onChange={(on) => toggleLogarithmic(on)} on={logarithmicOn} /> <span className="label">Logarithmic</span>
                        </div>
                        <div className="right">
                            <input className={(fromYearError ? 'invalid' : '')} type="number" value={showYears.from} onChange={(e) => setFromYear(e.target.value)} />
                            <input className={(toYearError ? 'invalid' : '')} type="number" value={showYears.to} onChange={(e) => setToYear(e.target.value)} />
                        </div>
                    </div>
                    <div onMouseMove={(e) => onChartOver(e)}>
                        <HighchartsStockChart colors={[
                            '#C42525'
                        ]}>
                        <Chart 
                            zoomType="x" 
                            stickyTracking={false} 
                            onClick={function(e){
                                if(e.xAxis[0] !== undefined){
                                    chartClicked(e.xAxis[0].value)
                                }
                            }}
                            height={ chartHeight }
                        />

                        <Tooltip 
                            split={false} 
                            sticky={true}
                            formatter={function(){
                            let theDate = Moment(this.x).format('MMM YYYY');
                            return [
                                '<div style="color: #E72121; padding: 2px 4px 0 4px; text-transform: uppercase; letter-spacing: 1px; font-size: 11px; font-family: clarika-bold, sans-serif;">' + theDate + '</div>',
                                ''
                            ]
                            }} 
                            backgroundColor={'#ffffff'}
                            className={'tooltip'}
                            borderColor={'transparent'}
                            useHTML={true}
                        />

                        <XAxis min={initialMin} max={initialMax} tickInterval= { theTickInterval } gridLineWidth={1}  onAfterSetExtremes={() => updateTickInterval()}></XAxis>

                        <YAxis maxPadding={0} type={axisType} gridLineWidth={0} startOnTick={true} min={null}>
                            <AreaSeries 
                                lineWidth={1} 
                                fillOpacity={0} 
                                id="dj" 
                                name="Value" 
                                onClick={function(e){
                                    chartClicked(e.point.x);
                                }} 
                                data={djData} 
                            />
                        </YAxis>


                        <RangeSelector>
                            <RangeSelector.Button offsetMax={0} offsetMin={0} count={1} type="month">1m</RangeSelector.Button>
                            <RangeSelector.Button offsetMax={0} offsetMin={0} count={12} type="month">1y</RangeSelector.Button>
                            <RangeSelector.Button offsetMax={0} offsetMin={0} count={120} type="month">10y</RangeSelector.Button>
                            <RangeSelector.Button offsetMax={0} offsetMin={0} type="all">All</RangeSelector.Button>
                        </RangeSelector>

                        <Navigator>
                            <Navigator.Series cursor="pointer" />
                            <Navigator.XAxis cursor="pointer"  tickInterval={1000 * 60 * 60 * 24 * 365 * 10} />
                        </Navigator>
                        </HighchartsStockChart>
                    </div>
                </div>
                <div className="descriptions">
                    <div className="fade bottom"></div>
                    <div className="inner" id="items">
                    { data.map((item, key) => (
                        <div key={item.itemKey} id={'item-' + key} className={"item" + (selectedIndex == item.itemKey ? ' active' : '') } onMouseEnter={() => onItemHover(key) } onMouseLeave={() => onItemOut(item.itemKey) } onClick={() => onItemClick(key) }>
                            <h3 className="date">{ Moment(item.date).format('MMM YYYY') }</h3>
                            <div className="text" dangerouslySetInnerHTML={{__html: item.text}}></div>
                        </div>
                    ))}
                    </div>
                </div>
                </div>
            </div>
            </div>
        ) }
    </div>
  );
}

export default withHighcharts(TheChart, Highcharts);