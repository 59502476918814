import React, { Component, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Menu from './menu';


const Years = ({minYear, maxYear, onHidePage}) => {

  let years = [];
  
  for(let i = 0; i < minYear % 10; i++){
    years.push(0);
  }
  for(let i = minYear; i <= maxYear; i++){
    years.push(i);
  }

  return (
    <div className="years-wp" id="years">
      <h1>The <span>TIME</span> Project</h1>
      <div className="years">
        { years.map((year, key) => (
          <div className={"year" + (year > 0 ? '' : ' desk')} key={key}>
            { year > 0 && <Link to={"/chart/" + year}><span>{year}</span></Link>}
          </div>
        ))}
      </div>
    </div>
  );
}

const Home = (props) =>  {

  const [ range, setRange ] = useState(null);

  useEffect(() => {
    fetch('./config.json').then(res => res.json()).then(res => {
      setRange({
        minYear: res.minYear,
        maxYear: res.maxYear
      })
    })
  }, []);

  return (
    <div>
      <div className="mob">
        <Menu />
      </div>
      <div className="width">
        { range &&
          <Years minYear={range.minYear} maxYear={range.maxYear} />
        }
      </div>
    </div>
  );
}

export default Home;